<template>
  <div class="history" :style="{height: `${height}px`, overflow: 'auto'}">
    <Spin fix v-show="loading">
      <Icon type="ios-loading" class="spin-icon-load" size="35"></Icon>
      <div>加载中，请稍后......</div>
    </Spin>
    <h1 class="h1Title">历史检查记录</h1>
    <Timeline v-if="history.length>0">
      <TimelineItem v-for="(item,index) in history" :key="index">
        <Alert>
          <p class="pItem"><Icon type="ios-clock" /> 检查时间：{{ item.updatedAt }}</p>
          <p class="pItem"><Icon type="ios-microphone" /> 检查结果：{{ item.result }}</p>
          <ys-page-list
              :header="false"
              :footer="false"
              :multiColumn="false"
              tableSize="small"
              tableHeight="auto"
              :tableData="item.itemList||[]"
              :headerColumns="headerColumns">
          </ys-page-list>
        </Alert>
      </TimelineItem>
    </Timeline>
    <ys-empty v-else title="暂无数据"></ys-empty>
  </div>
</template>

<script>

import {getDocHistory} from "@/api/common";
import {mapGetters} from 'vuex'

export default {
  name: "history",
  props: {
    mid: {type: [String, Number], default: null},
    parentId: {type: [String, Number], default: null},
  },
  data() {
    return {
      history: [],
      loading:false,
      headerColumns: [
        {width: 200, title: '资料名称', tooltip: true, key: 'name', align: 'left',},
        {width: 120, title: '检查结果', key: 'result', align: 'left', renderConfig: {type: 'baseData', parentName: '资料状态'}},
        {
          minWidth: 230, title: '检查附件', key: 'fileList', align: 'center', render: (h, params) => {
            return h('ys-file-list', {
              attrs: {
                class: 'tableFileList ys-file-list',
              },
              props: {
                fileList: (params.row.fileList || []).filter(item => item), type: 'onlyFile', width: 40, height: 40, gutter: 5,
              },
            })
          }
        },
      ],
    }
  },
  watch:{
    'mid':function (){
      this.getDetail();
    }
  },
  computed: {
    ...mapGetters(['height'])
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      getDocHistory({projectId: this.parentId, docCategoryId: this.mid}).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.history = res.data;
        }
      }).catch(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped lang="less">
.history {
  margin: 0 20px;

  .pItem {
    padding: 0px 0 10px 0;
  }
}
</style>
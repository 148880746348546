<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/projects/doc/GetInfo"
        :params="params"
        :header="false"
        :isPage="false"
        :multiBtn="multiBtn"
        @on-selection-change="checkItem"
        :tableHeight="tableHeight+60"
        :headerColumns="headerColumns">
      <template slot="detail">
        <History :parentId="mid" :mid="tableSelectItem.length===1?tableSelectItem[0].docCategoryId:''"></History>
      </template>
    </ys-page-list>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import History from "@/views/projects/list/detail/inspection/history";

export default {
  name: 'inspection',
  components: {History},
  mixins: [pageList],
  data() {
    return {
      params: {
        projectId: '',
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {width: 60, title: '排序', key: 'index', align: 'center'},
        {minWidth: 300, title: '需检查资料', tooltip: true, key: 'name', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 180, title: '备注', tooltip: true, key: 'desc', align: 'left'},
        {width: 120, title: '最新检查结果', key: 'result', align: 'left'},
        {
          minWidth: 230, title: '最新检查结果附件', key: 'projectName', align: 'center', render: (h, params) => {
            return h('ys-file-list', {
              attrs: {
                class: 'tableFileList ys-file-list',
              },
              props: {
                fileList: (params.row.itemList || []).map(item => item.fileList).flat(Infinity).filter(item => item), type: 'onlyFile', width: 40, height: 40, gutter: 8,
              },
            })
          }
        },
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    },
  },
  mounted() {
    this.params.projectId = this.mid;
    this.getList()
  },
  methods: {}
}
</script>
<style lang="less">
.tableFileList {
  padding-bottom: 10px;

  li {
    margin: 10px 0px 0 0;
  }
}
</style>

<template>
  <div class="home">
    <ys-page-list
      ref="table"
      :tableHeight="resultHeight + 100"
      :header="false"
      :footer="false"
      @on-selection-change="checkItem"
      :multiBtn="multiBtn"
      :multiColumn="false"
      :headerColumns="headerColumns"
      :tableLoading="tableLoading"
      :tableData="tableDataList"
    >
    </ys-page-list>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import {all, get} from 'ys-admin'

export default {
  name: '',
  mixins: [pageList],
  components: {},
  props: {},
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {minWidth: 150, title: '级别', key: 'standardTypeName', align: 'center'},
        {minWidth: 150, title: '类别', key: 'pname', align: 'center'},
        {width: 400, title: '工程范围', key: 'name', align: 'left'},
        {minWidth: 150, title: '结果', key: 'resultStr', align: 'center'},
        // {minWidth: 300, title: '附件', key: 'fileList', align: 'center', render: (h, params) => {
        //   return h('ys-file-list', {
        //     props: {
        //       fileList: (params.row.children || []).map(item => item.fileList).flat(Infinity).filter(item => item), type: 'onlyFile', width: 40, height: 40, gutter: 5,
        //     },
        //   })
        // }},
      ],
      modShow: false,
      params: {
        checkId: '',
      },
      tableLoading: false,
      tableDataList: [],
      projectId: ''
    }
  },
  computed: {
    multiBtn() {
      return []
    },
  },
  mounted() {
    this.params.checkId = this.mid
    this.getDataList();
  },
  methods: {
    async getDataList() {
      this.tableLoading = true
      this.tableDataList = []
      await all([
        get('/standardCheck/ListByProject', {standTypeId: 1, projectId: this.mid, limit: -1, isTop: false, noArea: true}),
        get('/standardCheck/ListByProject', {standTypeId: 2, projectId: this.mid, limit: -1, isTop: false, noArea: true})
      ]).then(res => {
        const greatDanger = (res[0]?.data || []).map(item => {
          (item.children || []).sort((a, b) => b.index - a.index).forEach(ite => ite.pname = item.name)
          return item.children
        }).flat(Infinity)
        const superGreatDanger =( res[1]?.data || []).map(item => {
          (item.children || []).sort((a, b) => b.index - a.index).forEach(ite => ite.pname = item.name)
          return item.children
        }).flat(Infinity)
        this.tableDataList = [...greatDanger, ...superGreatDanger]
      })
      this.tableLoading = false
    }
  }

}
</script>

import {get} from 'ys-admin'

/**
 * 项目详情
 * @param {Object} params
 * @returns {Object}
 */
export const projectInfo = function (params) {
  return get('/projects/GetInfo', params)
};

/**
 * 单体建筑详情
 * @param {Object} params
 * @returns {Object}
 */
 export const SingleBuildingInfo = function (params) {
  return get('/projectBuilding/Info', params)
};
/**
 * 单体建筑详情
 * @param {Object} params
 * @returns {Object}
 */
 export const getProjectOrgInfo = function (params) {
  return get('/projects/org/GetInfo', params)
};
 /**
 * 单体建筑详情
 * @param {Object} params
 * @returns {Object}
 */
 export const getProjectOrgList = function (params) {
  return get('/projects/org/List', params)
};
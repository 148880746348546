<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="check/ListByProject"
        :params="params"
        :multiBtn="multiBtn"
        :tableHeight="tableHeight"
        showContextMenu
        :multiColumn="true"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="任务类型" prop="type" v-if="!type">
          <Select v-model="params.type" transfer placeholder="检查单类型" style="width: 120px;">
            <Option v-for="(item,index) in baseData['检查单类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="任务状态" prop="statusList">
          <Select v-model="params.statusList" class="selectMultiple" multiple transfer clearable placeholder="任务状态" style="width: 150px;">
            <Option v-for="(item,index) in baseData['专项任务状态'].filter(item => item.value !== 4)" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="项目名称" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Detail from "@/views/task/list/detail";

export default {
  name: 'projects',
  mixins: [pageList],
  components: {Detail},
  props: {
    type: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      params: {
        projectId: '',
        userId: '',
        type: '',
        statusList: [],
        keyword: '',
      },
      putInFlag: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {title: '任务编号', width: 250, key: 'number', align: 'left'},
        {width: 200, title: '任务名称', key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {minWidth: 250, title: '项目名称', key: 'projectName', align: 'left'},
        {width: 120, title: '任务类型', key: 'type', align: 'center', renderConfig: {type: 'baseData', parentName: '检查单类型'}},
        {width: 120, title: '工程类型', key: 'projectCategory', align: 'center', renderConfig: {type: 'baseData', parentName: '项目类型'}},
        {width: 200, title: '施工单位', key: 'contractUnit', align: 'left'},
        {width: 120, title: '服务时间', key: 'serveTime', align: 'center', renderConfig: {type: 'formatDate'}},
        {width: 120, title: '状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '专项任务状态'}},
        {width: 170, title: '创建时间', key: 'createdAt', align: 'center'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              targetUrl: '/main/task/list/detail/' + (this.tableSelectItem.length === 1 ? this.tableSelectItem[0].id : null),
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/check/Remove', {id: this.tableSelectItem[0].id}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        },
      ]
    },
  },
  mounted() {
    this.params.type = this.type;
    this.params.projectId = this.mid;
    this.getList();
  },
  methods: {}
}
</script>

<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="120" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="楼号" prop="number">
        <Input v-model="form.number" placeholder="楼号"></Input>
      </FormItem>
      <FormItem label="高度" prop="height">
        <Input  type="number" v-model="form.height" placeholder="高度"><span slot="append">米</span></Input>
      </FormItem>
      <FormItem label="层数" prop="floor" style="width: 33%">
        <Input type="number" v-model="form.floor" placeholder="地上层数"><span slot="append">层</span></Input>
      </FormItem>
      <FormItem label="" prop="floorUnderground" :label-width="0" style="width: 17%">
        <Input  type="number" v-model="form.floorUnderground" placeholder="地下层数"><span slot="append">层</span></Input>
      </FormItem>
      <FormItem label="建筑面积" prop="area" style="width: 33%">
        <Input  type="number" v-model="form.area" placeholder="地上建筑面积"><span slot="append">㎡</span></Input>
      </FormItem>
      <FormItem label="" prop="areaUnderground" :label-width="0" style="width: 17%">
        <Input  type="number" v-model="form.areaUnderground" placeholder="地下建筑面积"><span slot="append">㎡</span></Input>
      </FormItem>
      <FormItem label="结构形式" prop="structure">
        <Input v-model="form.structure" placeholder="结构形式"></Input>
      </FormItem>
      <FormItem label="基础类型" prop="baseType">
        <Input v-model="form.baseType" placeholder="基础类型"></Input>
      </FormItem>
      <FormItem label="基础深埋" prop="baseDepth">
        <Input v-model="form.baseDepth" placeholder="基础深埋"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/projectBuilding/Add');">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import edit from '@/mixins/edit'
import {SingleBuildingInfo} from "@/api/projects";

export default {
  name: 'EditSingleBuilding',
  mixins: [edit],
  data() {
    return {
      ruleValidate: {},
      form: {
        id: '',
        projectId: '',
        number: '',
        height: '',
        floor: '',
        floorUnderground: '',
        area: '',
        areaUnderground: '',
        structure: '',
        baseType: '',
        baseDepth: ''
      },
    }
  },
  mounted() {
    this.form.projectId = this.parentID;
    if (this.mid) {
      SingleBuildingInfo({id: this.mid}).then(res => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item] || null;
        })
      })
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
</style>

<template>
  <div :style="{maxHeight:'calc(100vh - 190px)'}" style="overflow-y: auto">
    <ys-empty title="该项目未投保安责险或者未查询到保单信息" v-if="!mid"></ys-empty>
    <ys-cell-group title="" v-else>
      <ys-cell title="项目名称" :label="detail.projectName" block/>
      <ys-cell title="项目地址" :label="detail.address" block/>
      <ys-cell title="保单类型" :label="detail.type|getBaseDataName('保单类型')"/>
      <ys-cell title="子保单类型" :label="detail.subType|getBaseDataName('保单子类型')"/>
      <ys-cell title="保单编号" :label="detail.number" block/>
      <ys-cell title="保单开始" :label="detail.startAt|formatDate"/>
      <ys-cell title="保单截止" :label="detail.endAt|formatDate"/>
      <template v-if="detail.subType===101">
        <ys-cell title="保费总金额" :label="detail.premiumCost|fixed"/>
        <ys-cell title="项目保额" :label="detail.insureAmount|fixed"/>
        <ys-cell title="投保单位" :label="detail.enterpriseName"/>
        <ys-cell title="投保单位联系人" :label="detail.enterpriseContact+' ['+detail.enterprisePhone+']'"/>
        <ys-cell title="保险业务经理" :label="detail.companyContact+' ['+detail.companyPhone+']'"/>
      </template>
      <ys-cell title="承保单位" :label="detail.companyName"/>
      <ys-cell title="投保时间" :label="detail.insureAt|formatDate"/>
      <ys-cell title="投保单号" :label="detail.insureNum" block/>
      <ys-cell title="理赔记录" :label="(detail.compensate||0)|getBaseDataName('有无')"/>
      <ys-cell title="报告需审核" :label="(detail.isCheckReport||0)|getBaseDataName('是否')"/>
      <ys-cell title="保单无服务理由" v-if="detail.isServe===2" :label="detail.reason|getBaseDataName('保单无服务理由')"/>
      <ys-cell title="服务次数" :label="String(detail.serverCount)"/>
      <ys-cell title="服务机构" :label="(detail.serviceList||[]).map(item=>item.iname).join()"/>
      <ys-cell title="创建时间" :label="String(detail.createdAt)"/>
      <ys-cell title="备注" :label="detail.remarks"/>
    </ys-cell-group>
    <div v-if="detail.subType===101&&detail.isCoIns===1">
      <h1 class="h1Title">份额占比情况</h1>
      <table cellspacing="1" width="99%" cellpadding="0" border="0" class="baseTable">
        <tr>
          <th width="55%" align="left">机构名称</th>
          <th width="20%" align="center">保费占比</th>
          <th width="15%">比例</th>
          <th width="10%">引流方</th>
        </tr>
        <tr v-for="(subItem) in detail.policyCoInsurerList||[]" :key="subItem.id">
          <td>{{ subItem.insName }}</td>
          <td align="center">{{ subItem.cost || 0 }}元</td>
          <td align="center">{{ subItem.resultRate }}%</td>
          <td align="center">{{ subItem.isOwner ? '是' : '' }}</td>
        </tr>
      </table>
    </div>
    <div v-if="detail.subType===102">
      <ys-cell-group :title="item.type|getBaseDataName('保单类型')" v-for="item in detail.subPolicies||[]" :key="item.id" style="margin-top: 20px;">
        <ys-cell title="保单编号" :label="item.number"/>
        <ys-cell title="保单开始" :label="item.startAt"/>
        <ys-cell title="保单截止" :label="item.endAt"/>
        <ys-cell title="保费总金额" :label="item.premiumCost|fixed"/>
        <ys-cell title="项目保额" :label="item.insureAmount|fixed"/>
        <ys-cell title="投保单位" :label="item.enterpriseName"/>
        <ys-cell title="投保单位联系人" :label="item.enterpriseContact"/>
        <ys-cell title="投保单位联系方式" :label="item.enterprisePhone"/>
        <ys-cell title="承保单位" :label="item.companyName"/>
        <ys-cell title="保险业务经理" :label="item.companyContact"/>
        <ys-cell title="投保时间" :label="item.insureAt|formatDate"/>
        <ys-cell title="投保单号" :label="item.insureNum"/>
        <ys-cell title="联系方式" :label="item.companyPhone"/>
        <table cellspacing="1" width="99%" cellpadding="0" border="0" class="baseTable">
          <tr>
            <th width="55%" align="left">机构名称</th>
            <th width="20%" align="center">保费占比</th>
            <th width="15%">比例</th>
            <th width="10%">引流方</th>
          </tr>
          <tr v-for="(subItem) in (item.policyCoInsurerList||[])" :key="subItem.id">
            <td>{{ subItem.insName }}</td>
            <td align="center">{{ subItem.cost || 0 }}元</td>
            <td align="center">{{ subItem.resultRate }}%</td>
            <td align="center">{{ subItem.isOwner ? '是' : '' }}</td>
          </tr>
        </table>
      </ys-cell-group>
    </div>
  </div>
</template>

<script>
import {policyInfo} from "@/api/policy";

export default {
  name: "baseInfo",
  props: {mid: [String, Number]},
  data() {
    return {
      detail: {},
    }
  },
  watch: {
    "mid": function () {
      this.getDetail();
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      if (!this.mid) return false;
      this.detail = {};
      policyInfo({id: this.mid, random: new Date().getTime()}).then(res => {
        if (res.code === 200) {
          this.detail = res.data;
        }
      }).catch(() => {
        this.detail = {};
      })
    }
  }
}
</script>

<style scoped lang="less">

</style>
<template>
  <div>
    <ys-page-list
        ref="table"
        :params="params"
        action="/projectBuilding/List"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :multiBtn="multiBtn"
        showContextMenu
        :multiColumn="true"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词楼号" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增</Button>
        </ButtonGroup>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="900" title="新增/修改单体建筑">
      <EditSingle :mid="detailID" :parentID="mid" :midData="tableSelectItem" @on-ok="getList"></EditSingle>
    </ys-modal>
  </div>
</template>

<script>
import pageListMixins from '@/mixins/pageList';
import EditSingle from '@/views/projects/list/detail/building/EditSingle';

export default {
  name: 'SingleBuilding',
  mixins: [pageListMixins],
  components: {
    EditSingle
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/projectBuilding/Del', {ids: this.tableSelectItem.map(item => item.id)}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length <= 0
            },
          ]
        }
      ]
    },
  },
  data() {
    return {
      params: {
        projectId: ''
      },
      
      bindOrg: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {width: 220, title: '楼号', key: 'number', align: 'center'},
        {width: 120, title: '地上层数', key: 'floor', align: 'center'},
        {width: 120, title: '地下层数', key: 'floorUnderground', align: 'center'},
        {width: 120, title: '高度(m)', key: 'height', align: 'center'},
        {width: 120, title: '地上建面(㎡)', key: 'area', align: 'center'},
        {width: 120, title: '地下建面(㎡)', key: 'areaUnderground', align: 'center'},
        {width: 120, title: '结构形式', key: 'structure', align: 'center'},
        {width: 120, title: '基础类型', key: 'baseType', align: 'center'},
        {width: 120, title: '基础埋深', key: 'baseDepth', align: 'center'},
      ]
    }
  },
  mounted() {
    this.params.projectId = this.mid;
    this.getList()
  }
}
</script>
